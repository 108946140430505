<template>
  <Loading v-if="isLoading" />
  <div v-else class="static-pages-list">
    <b-card>
      <div
        class="p-2 custom-search d-flex align-items-center justify-content-between mb-1"
      >
        <h1 class="m-0 page-title">{{ $t("static_pages.static_pages") }}</h1>
        <div
          class="d-flex align-items-center"
          v-if="globalPermission.pages && globalPermission.pages.store"
        >
          <router-link
            class="btn btn-primary btn-add"
            :to="{ name: 'add-page' }"
          >
            <feather-icon icon="PlusIcon" class="text-white" size="16" />
            {{ $t("static_pages.add_new_page") }}
          </router-link>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="tableHeader"
        :rows="tableBody"
        styleClass="table-custom-style vgt-table striped condensed"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: $t('search_placeholder'),
        }"
      >
        <!-- Column: Name -->
        <template slot="table-column" slot-scope="props">
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("static_pages.name") }}
          </span>
          <span v-else-if="props.column.label === 'time'" class="text-nowrap">
            {{ $t("static_pages.time_and_date") }}
          </span>
          <span v-else-if="props.column.label === 'status'" class="text-nowrap">
            {{ $t("static_pages.status") }}
          </span>
          <span
            v-else-if="props.column.label === 'Actions'"
            class="text-nowrap"
          >
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span class="text-nowrap" v-if="props.column.field === 'name'">
            {{ props.row.name }}
          </span>
          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === '#'"
          >
            {{ props.row.index }}
          </span>

          <span
            class="text-nowrap"
            v-else-if="props.column.field === 'created_at'"
          >
            {{ $helpers.dateTimeInFormatWihtTime(props.row.updated_at) }}
          </span>

          <span
            class="text-nowrap custom-toggle"
            v-else-if="props.column.field === 'is_active'"
          >
            <b-form-checkbox
              :disabled="
                globalPermission.pages && !globalPermission.pages.update
              "
              switch
              v-model="props.row.is_active"
              @change="(e) => handleStaticPageVisibility(e, props.row.id)"
              :value="1"
              :unchecked-value="0"
            />
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    size="16"
                    icon="MoreVerticalIcon"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="globalPermission.pages && globalPermission.pages.update"
                  :to="{
                    name: 'edit-page',
                    params: { id: props.row.slug },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click.prevent="openExternalLink(props.row.slug)"
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>{{ $t("viewInWebSite") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="
                    globalPermission.pages && globalPermission.pages.destroy
                  "
                  @click="deletePage(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <div class="empty-table-message">
            <!-- Your custom content for the empty state goes here -->
            <p>{{ $t("noData") }}</p>
          </div>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-center flex-wrap">
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BCard,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/shared/loading/loading.vue";

export default {
  name: "static_pagesList",
  components: {
    VueGoodTable,
    BPagination,
    BButton,
    Loading,
    BCard,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
  },
  data() {
    return {
      isLoading: true,
      pageLength: 20,
      searchTerm: "",
      tableBody: [],
      tableHeader: [
        {
          label: "#",
          field: "#",
          sortable: false,
        },
        {
          label: "name",
          field: "title",
        },
        {
          label: "time",
          field: "created_at",
          sortable: false,
        },
        {
          label: "status",
          field: "is_active",
          sortable: false,
        },
        {
          label: "actions",
          field: "action",
          sortable: false,
        },
      ],
    };
  },
  beforeMount() {
    this.getPageList();
  },
  created() {
    if (
      this.globalPermission.pages &&
      !(this.globalPermission.pages.show || this.globalPermission.pages.destroy)
    ) {
      this.tableHeader = this.tableHeader.filter(
        (ele) => ele.label !== "actions"
      );
    }
  },
  computed: {
    webSiteBaseUrl() {
      return process.env.VUE_APP_WEB_SITE_BASE_URL;
    },
  },
  methods: {
    generateLink(slug) {
      return this.webSiteBaseUrl + slug;
    },
    async getPageList() {
      try {
        const response = (await this.$http.get("admin/pages")).data.data;
        this.tableBody = response;
        this.tableBody.map((el, index) => {
          (el.index = index + 1), (el.is_active = +el.is_active);
        });
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
    async handleStaticPageVisibility(checked, id) {
      try {
        this.overlayLoading = true;
        const data = {
          is_active: checked,
        };
        const res = await this.$http.post(
          `admin/pages/${id}?_method=put`,
          data
        );
        if (res.status > 299) {
          throw Error();
        }
        this.$helpers.makeToast("success", res.data.message, res.data.message);
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.overlayLoading = false;
      }
    },
    async deletePage(pageId) {
      try {
        this.$helpers.sweetAlertConfirm(
          this.$t("are_you_sure"),
          this.$t("you_wont_be_able_to_revert_this_action"),
          "warning",
          this.$t("g.yes_delete_it"),
          this.$t("g.cancel-button"),
          () => this.handleDeletePage(pageId)
        );
        this.overlayLoading = true;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    handleDeletePage(pageId) {
      this.$http
        .delete(`admin/pages/${pageId}`)
        .then((res) => {
          if (res.status === 200) {
            this.getPageList();
          }
        })
        .catch((err) => {
          this.$helpers.handleError(err);
        });
    },
    openExternalLink(slug) {
      window.open(this.generateLink(slug), "_blank");
    },
  },
};
</script>

<style lang="scss">
@import "./index";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
